<template>
  <div>
    <img style="width: 14.32vw;position: fixed;left: 18.75vw;top:1.875vw;z-index:10" src="../assets/logo.png">
    <img id="homeBg" style="width: 100%;position: relative;left: 0;top:0" src="../assets/bg.png">
    <span style="top:11.98vw;width: 26.09vw" class="span1">不跑「照相馆」 快速搞定证件照</span>
    <span style="top:21.04vw" class="span2">人像识别、精准抠图、美颜换装、无损存储</span>
    <img style="width: 13.59vw;position: absolute;left: 18.75vw;top:25.36vw" src="../assets/qrcode_image.png">
    <img style="width: 38.8vw;position: absolute;left: 47.45vw;top:8.59vw" src="../assets/iphone.png">
    <img style="width: 1.72vw;position: absolute;left: 47.76vw;top:49.27vw" src="../assets/icon_arrows.png">
    <div style="width: 100%;height: 53.33vw;position: relative">
      <img style="width: 25.31vw;position: absolute;top:2.66vw;left:7.92vw" src="../assets/bg_image_2.png">
      <img class="blue-tip" src="../assets/title_shape.png">
      <span class="span1">600多种证件照规格</span>
      <span style="top:25.83vw;" class="span2">涵盖寸照、考试报名证件照、社保证、形象照等600多种规格，轻松应对多种场景！</span>
      <img style="width: 31.25vw;position: absolute;top:4.9vw;left:48.18vw" src="../assets/ipgone_page1.png">
    </div>
    <div style="width: 100%;height: 53.33vw;position: relative">
      <img style="width: 26.3vw;position: absolute;top:0;left:70.78vw" src="../assets/bg_image_3.png">
      <img class="blue-tip" src="../assets/title_shape.png">
      <span class="span1">人像识别</span>
      <span class="span2">准确识别人脸，专业拍摄制作</span>
      <img style="width: 31.25vw;position: absolute;top:4.9vw;left:48.18vw" src="../assets/ipgone_page2.png">
    </div>
    <div style="width: 100%;height: 53.33vw;position: relative">
      <img style="width: 27.45vw;position: absolute;top:27.78vw;left:6.82vw" src="../assets/bg_image_4.png">
      <img class="blue-tip" src="../assets/title_shape.png">
      <span class="span1">精准抠图</span>
      <span class="span2">人像背景区分，自然过度虚化</span>
      <img style="width: 31.25vw;position: absolute;top:4.9vw;left:48.18vw" src="../assets/ipgone_page3.png">
    </div>
    <div style="width: 100%;height: 53.33vw;position: relative">
      <img style="width: 26.82vw;position: absolute;top:0;left:68.29vw" src="../assets/bg_image_5.png">
      <img class="blue-tip" src="../assets/title_shape.png">
      <span class="span1">美颜换装</span>
      <span class="span2">智能美颜技术，百搭正装任选</span>
      <img style="width: 31.25vw;position: absolute;top:4.9vw;left:48.18vw" src="../assets/ipgone_page4.png">
    </div>
    <div style="width: 100%;height: 53.33vw;position: relative">
      <img style="width: 25.05vw;position: absolute;top:9.65vw;left:5.68vw" src="../assets/bg_image_6.png">
      <img class="blue-tip" src="../assets/title_shape.png">
      <span class="span1">无损存储</span>
      <span class="span2">高清保存方式，方便快捷提取</span>
      <img style="width: 31.25vw;position: absolute;top:4.9vw;left:48.18vw" src="../assets/ipgone_page5.png">
    </div>
    <transition name="foot">
      <div v-show="footShow" class="foot">
        <div class="foot-1" style="display: flex;align-items: center">
          <img style="width: 1.04vw;margin-right: 1.04vw" src="../assets/foot/footer_icon_iphone.png">
          <span style="font-size: 0.73vw;color: white">联系方式：</span>
          <span style="font-size: 0.73vw;color: #8F93A8">18012485542</span>
        </div>
        <div class="foot-2" style="display: flex;align-items: center">
          <img style="width: 1.04vw;margin-right: 1.04vw" src="../assets/foot/footer_icon_iphone.png">
          <span style="font-size: 0.73vw;color: white">公司地址：</span>
          <span style="font-size: 0.73vw;color: #8F93A8">江苏省无锡市滨湖区平安财富中心14楼</span>
        </div>
        <div class="foot-6" style="display: flex;align-items: center">
          <img style="width: 1.04vw;margin-right: 1.04vw" src="../assets/foot/footer_icon_iphone.png">
          <span style="font-size: 0.73vw;color: white">友情链接：</span>
          <span class="link" style="font-size: 0.73vw;color: #8F93A8" @click="linkruhua">如画-见字如画</span>
        </div>
        <span class="foot-3" style="font-size: 0.68vw;color: #8F93A8">
          Copyright © 2019 无锡乐骐科技有限公司 | 苏公网安备 32021402001020号 苏ICP备14019013号-2
        </span>
        <img class="foot-4" src="../assets/foot/qrcode_image.png">
        <span class="foot-5" style="font-size: 0.73vw;color: white">
          扫码下载APP
        </span>
      </div>
    </transition>


  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data(){
      return{
          footShow:false
      }
  },
  methods:{
    linkruhua(){
      window.open('https://ruhua.ai/','_blank')
    }
  },
  mounted() {
      let that = this
      window.addEventListener('scroll', function () {
          //函数体
          if(document.documentElement.scrollTop>((1024/1920)*document.documentElement.clientWidth)/2){
              if (!that.footShow){
                  console.log('dada')
                  that.footShow = true
                  console.log('dada',that.footShow)
              }
          }else {
              if (that.footShow){
                  that.footShow = false
              }
          }
      })
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .span1{
    font-size: 3.33vw;
    line-height: 3.54vw;
    width: 23.33vw;
    text-align: left;
    color: #050A26;
    position: absolute;
    left: 18.85vw;
    top:17vw
  }
  .span2{
    font-size: 1.25vw;
    width: 27.45vw;
    text-align: left;
    color: #717C93;
    position: absolute;
    left: 18.85vw;

    top:23.22vw;
  }
  .blue-tip{
    width: 3.65vw;
    position: absolute;
    top: 13.44vw;
    left: 18.8vw;
  }
  .foot{
    width: 100%;
    height: 10.21vw;
    background-color: #25273E;
    position: fixed;
    bottom: 0;
  }
  .foot-enter-active {
    animation: foot-in 0.5s;
  }
  .foot-leave-active {
    animation: foot-in 0.5s reverse;
  }
  @keyframes foot-in {
    0% {
      height: 0;

    }

    100% {
      height: 10.21vw;

    }
  }

  .foot-1{
    position: absolute;
    left: 19.11vw;
    top:2.19vw;
  }
  .foot-2{
    position: absolute;
    left: 19.11vw;
    top:4.06vw;
  }
  .foot-3{
    position: absolute;
    left: 19.11vw;
    top:7.86vw;
  }
  .foot-4{
    position: absolute;
    left: 72.14vw;
    top:1.2vw;
    width: 6.04vw;
  }
  .foot-5{
    position: absolute;
    left: 72.65vw;
    top:7.86vw;
  }
  .foot-6{
    position: absolute;
    left: 19.11vw;
    top:6.06vw;
  }
  .link{
    cursor: pointer;
    user-select: none;
  }
</style>
